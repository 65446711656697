import React from 'react';
import { Container, Grid, Header, Card, Segment, Image } from 'semantic-ui-react';
import { UniversalLink, Icon } from '@plone/volto/components';
import { keys, isEmpty, map } from 'lodash';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { getFieldURL } from './utils';
import { getScaleUrl } from './utils';

const View = (props) => {
  const { data, mode } = props;
  return (
    <Segment as="section" className={'companyCards '} basic>
      <Container className={'mb-5 pb-5 '}>
        <Grid >
          {(data?.title || data?.description) && (
            <Grid.Row columns={12} >
              <Grid.Column mobile={12} tablet={12} computer={12} largeScreen={9} widescreen={9}>
                {data?.title && (<Header as="h2" className={"companyCards__title" + (data?.description ? ' cardsLisgin__title--wDesc' : ' cardsLisgin__title--noDesc')}>{data.title}</Header>)}
                {data?.description && (<p className="companyCards__desc">{data.description}</p>)}
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={12} className="companyCards__cardGroup">
            <Grid.Column mobile={12} tablet={6} computer={5} largeScreen={4} widescreen={4} >
              <Card as="figure" className='companyCardsItem companyCardsItem--ecity position-relative' fluid>
                <img className='companyCardsItem__icon'
                  src={
                    getScaleUrl(getFieldURL(data?.imgEcity), '') ||
                    DefaultImageSVG
                  }
                  alt='ecityclic espúblico.'
                /> 
                {data?.titleEcity &&
                  <Card.Content as="figcaption">
                    <Card.Header className="companyCardsItem__title">
                      <UniversalLink href={data?.linkEcity ? data?.linkEcity : '#'} className="companyCardsItem__link stretched-link">
                        {data?.titleEcity}
                      </UniversalLink>
                    </Card.Header>
                  </Card.Content>
                }
              </Card>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={6} computer={5} largeScreen={4} widescreen={4} >
              <Card as="figure" className='companyCardsItem companyCardsItem--gestiona position-relative' fluid>
                <img className='companyCardsItem__icon'
                  src={
                    getScaleUrl(getFieldURL(data?.imgGestiona), '') ||
                    DefaultImageSVG
                  }
                  alt='gestiona espúblico.'
                />                
                {data?.titleGestiona &&
                  <Card.Content as="figcaption">
                    <Card.Header className="companyCardsItem__title">
                      <UniversalLink href={data?.linkGestiona ? data?.linkGestiona : '#'} className="companyCardsItem__link stretched-link">
                        {data?.titleGestiona}
                      </UniversalLink>
                    </Card.Header>
                  </Card.Content>
                }
              </Card>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={6} computer={5} largeScreen={4} widescreen={4} >
              <Card as="figure" className='companyCardsItem companyCardsItem--drag position-relative' fluid>
              <img className='companyCardsItem__icon'
                  src={
                    getScaleUrl(getFieldURL(data?.imgDrag), '') ||
                    DefaultImageSVG
                  }
                  alt='drag espúblico.'
                />
                {data?.titleDrag &&
                  <Card.Content as="figcaption">
                    <Card.Header className="companyCardsItem__title">
                      <UniversalLink href={data?.linkDrag ? data?.linkDrag : '#'} className="companyCardsItem__link stretched-link">
                        {data?.titleDrag}
                      </UniversalLink>
                    </Card.Header>
                  </Card.Content>
                }
              </Card>
            </Grid.Column>
          </Grid.Row>

        </Grid>

      </Container>

    </Segment>

  )
};
export default View;