/**
 * Blog View component.
 * @module components/View/BlogView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer, Card } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { BodyClass } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../';

const BlogView = ({ content }) => {
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;

  return (
    <>
      <BodyClass className='blogView'/>

      <Container className="blogView__list">
        {content.items.map((item) => (
          <Card 
            as="article"
            key={item.url} 
            fluid
            className={'blogItem position-relative' + (item.image_field ? 'blogItem-hasImage' : 'blogItem-noImage')} 
            >
              <figure className={"blogItem__imgWrapper " + ( item?.content_color && ' p-5')} style={{ '--ecc-bg-blog-color': item?.content_color || 'transparent' }}>
                {item.content_icon && (
                  <div className='greenIcon'>
                    <Icon name={item.content_icon} />
                  </div> 
                )}
                {item.image_field && (
                  <PreviewImage
                    item={item}
                    alt={item.image_caption ? item.image_caption : ''}
                    className={"blogItem__img " + (item?.content_color && 'blogItem__img--contain')}
                    loading="lazy"
                    sizes="(max-width: 991px) 100vw, (max-width: 1599px) 33vw, 30rem"
                    />                  
                    )}
              </figure>
              
            <Card.Content className="blogItem__text">
              <Card.Header as="h2" className="blogItem__title">
                <UniversalLink item={item} className="blogItem__link stretched-link">
                  {item.title}
                </UniversalLink>
              </Card.Header>
              {item.description && 
                <Card.Description as="p" className="blogItem__desc">{item.description}</Card.Description>
              }
              <Card.Content extra>
                <span className="readMoreItem" aria-hidden="true">
                  <FontAwesomeIcon className='readMoreItem__icon' icon={faCirclePlus} />
                  {item.textLink ? (<span>{item.textLink}</span>) : (<FormattedMessage id="Read more" defaultMessage="Read more" />)}
                </span>
              </Card.Content>
            </Card.Content>              
          </Card>
        ))}
      </Container>
    </>
  );
};


export default BlogView;
