/**
 * Slides component.
 * @module components/Frontpage/Slides
 */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Container, Header, Button } from 'semantic-ui-react';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isEmpty } from 'lodash';
import { Claim } from '../';


const Slides = (props) => { 
  const { content } = props;
  const dispatch = useDispatch();
  const slides = useSelector((state) => state.search.subrequests.slides?.items);
  
	const getSlidesPath = function (content) {
	  let language = content.language.token
	  let path = '/' + content.id + '/home/slides'

	  if (language === 'ca')
	    path = '/' + content.id + '/portada/slides'
	  if (language === 'es')
	    path = '/' + content.id + '/portada/slides'

	  return path
	}
  const slidesPath = getSlidesPath(content)

  React.useEffect(() => {
    dispatch(
      searchContent(
        slidesPath,
        {
          portal_type: ['Slide'],
          review_state: 'published',
          b_size: 4,
          sort_on: 'getObjPositionInParent',
          metadata_fields: ['remoteUrl', 'button_text']
        },
        'slides',
      ),
    );
  }, [dispatch]);

	return !isEmpty(slides) ? (
		<Container as="section" id="claim-front-page">
      <h2 className="visually-hidden"><FormattedMessage id="About us" defaultMessage="About us"  /></h2>
			<Claim items={slides} />
		</Container>
	) : (
    <></>
  );

}

export default Slides;