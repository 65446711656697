/**
 * Claim component.
 * @module components/Claim/Claim
 */

import React, { useState } from 'react';
import { Container, Header, Button } from 'semantic-ui-react';
import { UniversalLink, Image } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';

import logosPNG from '../../icons/logos.png';

const Claim = (props) => {
  const { items, initialSlide } = props;
  const [isShown, setIsShown] = useState(initialSlide !== undefined ? initialSlide : 0);

  function handleShowItem(index) {
    setIsShown(index);
  }

  return (
    <>
      <div className='claimFP'>
        {items && items.slice(0, 4).map((item, index) => (
          <React.Fragment key={item['@id']}>
            <article className={isShown === index ? 'claimFPItem selected' : 'claimFPItem '} >
              <div className="ui image claimFPItem__img">
                <Image item={item} alt="" />
              </div>
              <div className="claimFPItem__text ">
                <Header className="claimFPItem__title guaxi w-75" as="h3"  >{item.title}</Header>
                {item?.description ? (
                  <p className='claimFPItem__desc guaxi'>{item.description}</p>
                ) : (
                  <Image src={logosPNG} alt="ecityclic gestiona drag" className="w-75 claimFPItem__logos guaxi" />
                )}
                {item?.remoteUrl &&
                  <p className='mb-5 mt-4 pb-4 pt-3'>
                    <UniversalLink
                      className="claimFPItem__link guaxi button ui inverted"
                      href={item.remoteUrl}>{item.button_text}</UniversalLink>
                  </p>
                }
              </div>
            </article>
            <Button className={isShown === index ? 'claimFP__button selected' : 'claimFP__button'} basic onMouseEnter={() => handleShowItem(index)} >
              {item.title}
            </Button>
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

export default Claim;