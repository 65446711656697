/**
 * HeaderContentInfo view component.
 * @module components/Header/HeaderContentInfo
 */

import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Grid, Button, Header, Container, Segment } from 'semantic-ui-react';
import { UniversalLink, Image } from '@plone/volto/components';
import { flattenToAppURL, BodyClass } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';
import { Icon } from '../';


const messages = defineMessages({
  headerLinkTitleOffer: {
    id: 'Sign up for this offer',
    defaultMessage: 'Sign up for this offer',
  },
});


const HeaderContentInfo = (props) => {
  const intl = useIntl();
  const content = useSelector((state) => state?.content?.data);
  const headerStyleType = useSelector((state) => state?.content?.data?.header_style_type?.token);
  const contentColor = useSelector((state) => state?.content?.data?.content_color);
  const title = useSelector((state) => state?.content?.data?.title);
  const preTitle = useSelector((state) => {
    if (state?.content?.data?.pretitle) {
      return state?.content?.data?.pretitle;
    }
    if (state?.content?.data?.newsitem_subjects) {
      return state?.content?.data?.newsitem_subjects[0].title;
    }
    return state?.content?.data?.pretitle;
  });
  const description = useSelector((state) => state?.content?.data?.description);
  const headerLink = useSelector((state) => {
    if (state?.content?.data?.header_link) {
      return state?.content?.data?.header_link
    }
    if (state?.content?.data?.['@type'] == 'Offer') {
      return '#offer-form'
    }
    return state?.content?.data?.header_link
  });
  const headerLinkTitle = useSelector((state) => {
    if (state?.content?.data?.header_link_title) {
      return state?.content?.data?.header_link_title
    }
    if (state?.content?.data?.['@type'] == 'Offer') {
      return intl.formatMessage(messages.headerLinkTitleOffer)
    }
    return state?.content?.data?.header_link_title
  });
  const icon = useSelector((state) => state?.content?.data?.content_icon);
  const image = useSelector((state) => state?.content?.data?.preview_image);
  const type = useSelector((state) => state?.content?.data?.['@type']);
  const previewCaption = useSelector((state) => state?.content?.data?.preview_caption);
  const headerStyleTwoTypes = ['News Item', 'Solution']
  const { pathname, isCmsUI } = props;

  // Animate green Icon
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Set animate to true after the page loads
    setAnimate(true);

    // Set main color
    document.body.style.setProperty("--ecc-company-color", contentColor);
  }, [contentColor]);

  return (
    <>
      {isCmsUI ? (
        <div className="bg-background-01 text-white">
          {title && (<Container><Header as="h1" className="documentFirstHeading innerHeader__title mt-5">{title}</Header></Container>)}
        </div>

      ) : (
        <>
          {(headerStyleType === 'header-style-one' || (headerStyleType === undefined && !headerStyleTwoTypes.includes(type)) ) && (
            <div className={'innerHeader__wrapper ' + (image ? 'innerHeader__wrapper--withImage' : 'innerHeader__wrapper--noImage')} >
              <Container>
                <Grid >

                  <Grid.Row columns={12}>
                    <Grid.Column mobile={12} tablet={12} computer={6} largeScreen={6} className="flex-grow-1">
                      <div className="innerHeader__text ">
                        {preTitle && (<p className='innerHeader__title__prefix'>{preTitle}</p>)}
                        {title && (<Header as="h1" className="documentFirstHeading innerHeader__title">{title}</Header>)}
                        {description && (<p className="documentDescription innerHeader__description">{description}</p>)}

                        {headerLink && (
                          <UniversalLink className="ui button inverted ms-auto innerHeader__backButton mb-4 d-print-none"
                            href={flattenToAppURL(headerLink)}>
                            {headerLinkTitle ? (headerLinkTitle) : (<FormattedMessage id="More information" defaultMessage="More information" />)}
                          </UniversalLink>
                        )}

                      </div>
                    </Grid.Column>
                    {image && (
                      <Grid.Column mobile={12} tablet={8} computer={6} largeScreen={5} widescreen={5} floated='right'>
                        <div className="innerHeader__imgWrapper">
                          {icon && (
                            <div className={'greenIcon ' + (animate ? ' showGreenIconAnim' : ' ')} >
                              <Icon name={icon} />
                            </div>
                          )}

                          <Image
                            className={"innerHeader__img" + (animate ? ' showHeaderImageAnim' : ' ')}
                            alt=""
                            item={content}
                            imageField="preview_image"
                            sizes="(max-width: 992px) 100vw, 50vw"
                          />

                        </div>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </Container>
            </div>
          )}

          {(headerStyleType === 'header-style-two' || (headerStyleType === undefined && headerStyleTwoTypes.includes(type))) && (
            <div className="innerHeader2__wrapper">
              <Container>
                {preTitle && (<p className='innerHeader__title__prefix'>{preTitle}</p>)}
                {title && (<Header as="h1" className="documentFirstHeading innerHeader__title">{title}</Header>)}
                {description && (<p className="documentDescription innerHeader__description">{description}</p>)}

                {headerLink && (
                  <p className="text-center">
                    <UniversalLink className="ui button inverted ms-auto innerHeader__backButton mb-4 d-print-none"
                      href={flattenToAppURL(headerLink)}>
                      {headerLinkTitle ? (headerLinkTitle) : (<FormattedMessage id="More information" defaultMessage="More information" />)}
                    </UniversalLink>
                  </p>
                )}
              </Container>
              {image && (
                <div className="innerHeader2__imgWrapper">
                  <Container as="figure" className="mb-0">
                    <Image
                      className={"innerHeader2__img" + (animate ? ' showHeaderImageAnim' : ' ')}
                      alt=""
                      item={content}
                      imageField="preview_image"
                      sizes="100vw"
                    />
                    {previewCaption && (
                      <figcaption className='innerHeader2__img__caption'>
                        {previewCaption}
                      </figcaption>
                    )}
                  </Container>
                </div>
              )}
            </div>
          )}

          {/* Header style 3. Company presentation */}
          {(headerStyleType === 'header-style-three' ) && (
            <div className={'innerHeader__wrapper innerHeader3__wrapper ' + (image ? 'innerHeader__wrapper--withImage' : 'innerHeader__wrapper--noImage')} >
              <BodyClass className='type3Heading' />
              <Container>
                <Grid >

                  <Grid.Row columns={12}>
                    <Grid.Column mobile={12} tablet={8} computer={6} largeScreen={6} className="flex-grow-1">
                      <div className="innerHeader__text "> 
                        {preTitle && (<Header as="h1" className="documentFirstHeading innerHeader__title">{preTitle}</Header>)}
                        {description && (<p className="documentDescription innerHeader__description">{description}</p>)}

                        {headerLink && (
                          <UniversalLink className="ui button inverted ms-auto innerHeader__backButton mb-4 d-print-none"
                            href={flattenToAppURL(headerLink)}>
                            {headerLinkTitle ? (headerLinkTitle) : (<FormattedMessage id="More information" defaultMessage="More information" />)}
                          </UniversalLink>
                        )}

                      </div>
                    </Grid.Column>
                    {image && (
                      <Grid.Column mobile={12} tablet={4} computer={6} largeScreen={5} widescreen={5} floated='right'>
                        <div className="innerHeader__imgWrapper">
                          <Image
                            className={"innerHeader__img" + (animate ? ' showHeaderImageAnim' : ' ')}
                            alt=""
                            item={content}
                            imageField="preview_image"
                            sizes="(max-width: 992px) 100vw, 50vw"
                          />
                        </div>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </Container>
            </div>
          )}
        </>
      )}

    </>
  );
};


export default HeaderContentInfo;
