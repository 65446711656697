import { 
  Frontpage,
  BusinessLine,
  Offer,
  Project,
  Solution,
  BlogView,
  Blog2View,
  NewsListing,
  ProjectsListing,
  ContactView,
  // Blocks
  GridEdit,
  GridView,
  GridBlockDataAdapter,
  GridBlockSchema,
  GridTemplates,
  IconEdit,
  IconView,
  ClientReferencesEdit,
  ClientReferencesView,
  IconListingEdit,
  IconListingView,
  PointsListingEdit,
  PointsListingView,
  FeaturedListingEdit,
  FeaturedListingView,
  TabsListingEdit,
  TabsListingView,
  SlideCardsEdit,
  SlideCardsView,
  TestimonialsEdit,
  TestimonialsView,
  TimeLineEdit,
  TimeLineView,
  OffersEdit,
  OffersView,
  CardListingEdit,
  CardListingView,
  CompanyCardsEdit,
  CompanyCardsView,
  FqaListEdit,
  FqaListView,
  // Widgets
  IconSelectorWidget,
  ResultRenderer,
  MultilingualTermWidget,
  FileCustomWidget,
  CheckboxListWidget,
  CheckboxWidget,
  HtmlLabelWidget,
  GoogleReCaptchaWidget,
  SimpleColorPicker,
  // Icons
  ecityclicIconsList,
  brandsIconsList,
} from './components';

import {
  defaultPanelConfig
} from './config';

import UrlWidget from '@plone/volto/components/manage/Widgets/UrlWidget';
import heroSVG from '@plone/volto/icons/hero.svg'; 
import gridSVG from '@plone/volto/icons/grid-block.svg';
import listSVG from '@plone/volto/icons/content-listing.svg';
import listBulletSVG from '@plone/volto/icons/list-bullet.svg';
import listArrowsSVG from '@plone/volto/icons/list-arrows.svg';
import interfaceSVG from '@plone/volto/icons/interface.svg';
import cardsSVG from '@plone/volto/icons/cards.svg';
import commentsSVG from '@plone/volto/icons/comment.svg';
import suitcaseSVG from '@plone/volto/icons/briefcase.svg';
import recentSVG from '@plone/volto/icons/recent-changes.svg';
import userSVG from '@plone/volto/icons/user.svg';
import commandSVG from '@plone/volto/icons/command.svg';
import espublicoSVG from './icons/gestiona-logo.svg';

import reducers from './reducers';

const applyConfig = (config) => {
  // Mutilingual
  config.settings.isMultilingual = true;
  config.settings.supportedLanguages = ['ca', 'es'];
  config.settings.defaultLanguage = 'ca';

  // Navigation
  config.settings.navDepth = 2;

  // Opengraph image
  config.settings.contentMetadataTagsImageField = 'preview_image';

  // Hide tags
  config.settings.showTags = false;

  // Cookie Consent
  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: defaultPanelConfig,
  };

  // Views
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      LRF: Frontpage,
      BusinessLine: BusinessLine,
      Offer: Offer,
      Project: Project,
      Solution: Solution,
    },
    layoutViews: {
      ...config.views.layoutViews,
      blog_view: BlogView,
      blog2_view: Blog2View,
      news_listing_view: NewsListing,
      projects_listing_view: ProjectsListing,
      contact_view: ContactView,
    },
    layoutViewsNamesMapping: {
      ...config.views.layoutViewsNamesMapping,
      blog_view: 'Blog',
      blog2_view: 'Blog 2',
      news_listing_view: 'News listing',
      projects_listing_view: 'Projects listing',
      contact_view: 'Contact',
    },
  };

  // Reducers
  config.addonReducers = {
    ...config.addonReducers,
    ...reducers,
  };

  // Allow to delete title block
  config.blocks.requiredBlocks = [];

  // Blocks
  config.blocks.groupBlocksOrder = [
    ...config.blocks.groupBlocksOrder,
    { id: 'ecityclic', title: 'ecityclic' }
  ];

  // Grid
  config.blocks.blocksConfig.gridBlock = {
    id: 'gridBlock',
    title: 'Grid',
    icon: gridSVG,
    group: 'common',
    view: GridView,
    edit: GridEdit,
    blockSchema: GridBlockSchema,
    dataAdapter: GridBlockDataAdapter,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    templates: GridTemplates,
    maxLength: 4,
    allowedBlocks: ['image', 'video', 'listing', 'slate', 'teaser', 'icon', 'maps'],
  };
  // Icon
  config.blocks.blocksConfig.icon = {
    id: 'icon',
    title: 'Icon',
    icon: commandSVG,
    group: 'common',
    view: IconView,
    edit: IconEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    blockHasOwnFocusManagement: false,
    variations: [
      {
        id: 'default',
        isDefault: true,
        title: 'Default',
        template: IconView,
      },
    ],
  };
  config.blocks.blocksConfig.icon.variations.push({
    id: 'variation2',
    template: IconView,
    title: 'variation #2',
  });
  // Client References
  config.blocks.blocksConfig.clientReferences = {
    id: 'clientReferences',
    title: 'Client References',
    icon: suitcaseSVG,
    group: 'ecityclic',
    view: ClientReferencesView,
    edit: ClientReferencesEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  // Icon Listing
  config.blocks.blocksConfig.iconListing = {
    id: 'iconListing',
    title: 'Icon Listing',
    icon: listSVG,
    group: 'ecityclic',
    view: IconListingView,
    edit: IconListingEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  // Points Listing
  config.blocks.blocksConfig.pointsListing = {
    id: 'pointsListing',
    title: 'Points Listing',
    icon: listBulletSVG,
    group: 'ecityclic',
    view: PointsListingView,
    edit: PointsListingEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  // Featured Listing
  config.blocks.blocksConfig.featuredListing = {
    id: 'featuredListing',
    title: 'Featured Listing',
    icon: listArrowsSVG,
    group: 'ecityclic',
    view: FeaturedListingView,
    edit: FeaturedListingEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  // Tabs Listing
  config.blocks.blocksConfig.tabsListing = {
    id: 'tabsListing',
    title: 'Tabs Listing',
    icon: interfaceSVG,
    group: 'ecityclic',
    view: TabsListingView,
    edit: TabsListingEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  // CardSlider
  config.blocks.blocksConfig.slidercards = {
    id: 'slidercards',
    title: 'SliderCards',
    group: 'ecityclic',
    icon: cardsSVG,
    view: SlideCardsView,
    edit: SlideCardsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  // Testimonials
  config.blocks.blocksConfig.testimonials = {
    id: 'testimonials',
    title: 'Testimonials',
    group: 'ecityclic',
    icon: commentsSVG,
    view: TestimonialsView,
    edit: TestimonialsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  // TimeLine
  config.blocks.blocksConfig.timeLine = {
    id: 'timeLine',
    title: 'TimeLine',
    group: 'ecityclic',
    icon: recentSVG,
    view: TimeLineView,
    edit: TimeLineEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  // Offers
  config.blocks.blocksConfig.offers = {
    id: 'offers',
    title: 'Offers',
    group: 'ecityclic',
    icon: userSVG,
    view: OffersView,
    edit: OffersEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  // CardListing
  config.blocks.blocksConfig.cardListing = {
    id: 'cardListing',
    title: 'CardListing',
    group: 'ecityclic',
    icon: heroSVG,
    view: CardListingView,
    edit: CardListingEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  // CompanyCards
  config.blocks.blocksConfig.companyCards = {
    id: 'companyCards',
    title: 'Company Cards',
    group: 'ecityclic',
    icon: espublicoSVG,
    view: CompanyCardsView,
    edit: CompanyCardsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.fqaList = {
    id: 'fqaList',
    title: 'FqaList',
    icon: commandSVG,
    group: 'ecityclic',
    view: FqaListView,
    edit: FqaListEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  // Widgets
  // Icon Selector
  config.widgets.widget.icon_selector = IconSelectorWidget;
  config.settings.widget.icon_selector = {
    ...config.settings.widget.icon_selector,
    iconList: {
      ...config.settings.widget.icon_selector.iconList,
      ecityclic: ecityclicIconsList,
      brands: brandsIconsList,
    },
    defaultRenderer: {
      ...config.settings.widget.icon_selector.defaultRenderer,
      ResultRenderer
    }
  }
  // Multilingual Term
  config.widgets.widget.multilingual_term_widget = MultilingualTermWidget;
  // File Custom
  config.widgets.widget.file_custom_widget = FileCustomWidget
  // Checkbox List
  config.widgets.widget.checkbox_list_widget = CheckboxListWidget
  // Checkbox
  config.widgets.widget.checkbox_widget = CheckboxWidget
  // Html Label
  config.widgets.widget.html_label_widget = HtmlLabelWidget
  // Google ReCaptcha
  config.widgets.widget.google_recaptcha_widget = GoogleReCaptchaWidget

  config.widgets = {
    ...config.widgets,
    id: {
      ...config.widgets.id,
      content_icon: IconSelectorWidget,
      content_color: SimpleColorPicker ,
      header_link: UrlWidget,
    },
  };

  return config;
};

export default applyConfig;
