import React from 'react';
import { FormFieldWrapper, Icon } from '@plone/volto/components';
import { Button } from 'semantic-ui-react';
import clearSVG from '@plone/volto/icons/clear.svg';

import loadable from '@loadable/component';
const GithubPicker = loadable(() => import('react-color/lib/Github'));

const colors = [
  '#7dad14',
  '#005c73',
  '#002e54',
  '#d63384',
  '#dc3545',
  '#fd7e14',
  '#ffc107',
  '#198754',
  '#20c997',
  '#0dcaf0',
  '#000',
  '#fff',
  '#979797',
  '#393939',
];

export default (props) => {
  const { id, value, onChange, available_colors } = props;
  const [showPicker, setShowPicker] = React.useState(false);
  console.log(available_colors)

  return (
    <FormFieldWrapper
      {...props}
      draggable={false}
      className="simple-color-picker-widget"
    >
      <div className="wrapper">
        <Button.Group>
          <div
            style={{ backgroundColor: value ? value : '#000', width: '30px', height: '30px' }}
            onClick={() => setShowPicker(!showPicker)}
            size="huge"
            title="Pick color"
          >
            {''}
          </div>
          <div
            compact
            style={{ paddingLeft: '8px', paddingRight: '0px' }}
            onClick={() => {
              setShowPicker(false);
              onChange(id, null);
            }}
          >
            <Icon name={clearSVG} size="18px" color="red" />
          </div>
        </Button.Group>

        {showPicker ? (
          <>
          <strong>Colors disponibles</strong>
          <span>{available_colors}</span>
          <GithubPicker
            width="180px"
            className="color-picker"
            colors={available_colors ? available_colors : colors}
            color={value || '#000'}
            onChangeComplete={(value) => {
              setShowPicker(false);
              onChange(id, value.hex);
            }}
          />
          </>
        ) : (
          ''
        )}
      </div>
    </FormFieldWrapper>
  );
};
