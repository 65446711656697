import { defineMessages } from 'react-intl';

const messages = defineMessages({
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  url: {
    id: 'URL',
    defaultMessage: 'URL',
  },
  background: {
    id: 'background',
    defaultMessage: 'Background',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  cards: {
    id: 'Cards',
    defaultMessage: 'Cards',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  titleEcity: {
    id: 'Text ecityclic',
    defaultMessage: 'Text ecityclic'
  },
  linkEcity: {
    id: 'Link ecityclic',
    defaultMessage: 'Link ecityclic'
  },
  imgEcity: {
    id: 'Image ecityclic',
    defaultMessage: 'Image ecityclic'
  },
  titleGestiona: {
    id: 'Text gestiona',
    defaultMessage: 'Text gestiona'
  },
  linkGestiona: {
    id: 'Link gestiona',
    defaultMessage: 'Link gestiona'
  },
  imgGestiona: {
    id: 'Image gestiona',
    defaultMessage: 'Image gestiona'
  },
  titleDrag: {
    id: 'Text drag',
    defaultMessage: 'Text drag'
  },
  linkDrag: {
    id: 'Link drag',
    defaultMessage: 'Link drag'
  },
  imgDrag: {
    id: 'Image drag',
    defaultMessage: 'Image drag'
  }
});
const cardSchema = (props) => ({
  title: 'Cards',
  addMessage: props.intl.formatMessage(messages.addItem),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'description', 'icon'],
    },
  ],

  properties: {
    title: {
      title: props.intl.formatMessage(messages.title),
      widget: 'text',
    },
    description: {
      title: props.intl.formatMessage(messages.description),
      widget: 'textarea',
    },
    icon: {
      title: props.intl.formatMessage(messages.icon),
      widget: 'icon_selector',
    },
  },

  required: ['title'],
});


const schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'titleEcity', 'linkEcity', 'imgEcity', 'titleGestiona', 'linkGestiona', 'imgGestiona', 'titleDrag', 'linkDrag', 'imgDrag'],
      },
    ],
    properties: {
      title: {
        title: props.intl.formatMessage(messages.title),
        widget: 'text',
      },
      titleEcity: {
        title: props.intl.formatMessage(messages.titleEcity),
        widget: 'text',
      },
      linkEcity: {
        title: props.intl.formatMessage(messages.linkEcity),
        widget: 'url',
      },
      imgEcity: {
        title: props.intl.formatMessage(messages.imgEcity),
        widget: 'attachedimage',
      },
      titleGestiona: {
        title: props.intl.formatMessage(messages.titleGestiona),
        widget: 'text',
      },
      linkGestiona: {
        title: props.intl.formatMessage(messages.linkGestiona),
        widget: 'url',
      },
      imgGestiona: {
        title: props.intl.formatMessage(messages.imgGestiona),
        widget: 'attachedimage',
      },
      titleDrag: {
        title: props.intl.formatMessage(messages.titleDrag),
        widget: 'text',
      },
      linkDrag: {
        title: props.intl.formatMessage(messages.linkDrag),
        widget: 'url',
      },
      imgDrag: {
        title: props.intl.formatMessage(messages.imgDrag),
        widget: 'attachedimage',
      },
    },
  };
};

export default schema;